import React, { useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Snackbar,
} from "@mui/material";
import { CreateFileButton, ImportButton } from "./Buttons";
import DeleteIcon from "@mui/icons-material/Delete";

function FileDrawer({
  handleFileClick,
  selectedFile,
  fileContents,
  setFileContents,
}) {
  const [files, setFiles] = useState([
    "read-write.rs",
    "reverse.c",
    "checksum.c",
  ]);
  const [newFileName, setNewFileName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [hoveredFile, setHoveredFile] = useState(null);
  const [warning, setWarning] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleAddFile = () => {
    setIsAdding(true);
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleFileNameSubmit = (e) => {
    if (e.key === "Enter" && newFileName.trim() !== "") {
      const newFile = newFileName.trim();
      if (/^[\w\-\.]+\.(c|rs)$/.test(newFile)) {
        setFiles((prevFiles) => {
          if (!prevFiles.includes(newFile)) {
            return [...prevFiles, newFile];
          }
          return prevFiles;
        });
        setNewFileName("");
        setIsAdding(false);
        handleFileClick(newFile);
        setWarning("");
      } else {
        setWarning("Invalid file type. Please add a Rust of C file");
        setSnackbarOpen(true);
      }
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files.filter((file) => file !== fileName);
    setFiles(updatedFiles);

    if (fileName === selectedFile && updatedFiles.length > 0) {
      handleFileClick(updatedFiles[0]);
    } else if (updatedFiles.length === 0) {
      handleFileClick("");
    }
  };

  const onDrop = (acceptedFiles) => {
    const fileContentsMap = {};

    acceptedFiles.forEach((file) => {
      const fileType = file.name.split(".").pop();
      if (fileType === "c" || fileType === "rs") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          fileContentsMap[file.name] = fileContent;

          setFiles((prevFiles) => {
            if (!prevFiles.includes(file.name)) {
              return [...prevFiles, file.name];
            }
            return prevFiles;
          });

          setFileContents((prevContents) => ({
            ...prevContents,
            ...fileContentsMap,
          }));

          handleFileClick(file.name, fileContent);
          setWarning("");
        };
        reader.readAsText(file);
      } else {
        setWarning("Invalid file type. Please upload .c or .rs files.");
        setSnackbarOpen(true);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".c, .rs",
    multiple: true,
    directory: true,
  });

  const handleDownloadFile = (fileName) => {
    const blob = new Blob([fileContents], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const newFile = uploadedFile.name;
      const fileType = newFile.split(".").pop();
      if (fileType === "c" || fileType === "rs") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          setFiles((prevFiles) => {
            if (!prevFiles.includes(newFile)) {
              return [...prevFiles, newFile];
            }
            return prevFiles;
          });
          handleFileClick(newFile, fileContent);
          setWarning("");
        };
        reader.readAsText(uploadedFile);
      } else {
        setWarning("Invalid file type. Please upload .c or .rs files.");
        setSnackbarOpen(true);
      }
    }
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "0 8px",
          backgroundColor: "#FCFCFD",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <Tooltip
          title="Import File"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -15],
                  },
                },
              ],
            },
          }}
        >
          <span>
            <ImportButton />
          </span>
        </Tooltip>
        <input
          {...getInputProps({
            webkitdirectory: "true",
            multiple: true,
          })}
          style={{ display: "none" }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginRight: 2,
          }}
        >
          Files
        </Typography>
        <Tooltip
          title="Create New File"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleAddFile();
            }}
          >
            <span style={{ paddingRight: "4px" }}>
              <CreateFileButton />
            </span>
          </div>
        </Tooltip>
      </Stack>

      <Divider />

      <List>
        {files.map((fileName) => (
          <ListItem
            button
            key={fileName}
            onClick={() => handleFileClick(fileName)}
            onMouseEnter={() => setHoveredFile(fileName)}
            onMouseLeave={() => setHoveredFile(null)}
            style={{
              backgroundColor:
                selectedFile === fileName ? "#FFEEE5" : "transparent",
              padding: "4px 4px",
            }}
          >
            <ListItemText
              primary={truncateText(fileName, 20)}
              sx={{
                textAlign: "center",
                color: selectedFile === fileName ? "#F74E00" : "#B9B9B9",
              }}
            />
            {hoveredFile === fileName && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFile(fileName);
                }}
                size="small"
                style={{
                  position: "absolute",
                  right: "8px",
                }}
                disableRipple
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListItem>
        ))}
        {isAdding && (
          <ListItem>
            <TextField
              fullWidth
              autoFocus
              placeholder="Enter file name"
              value={newFileName}
              onChange={handleFileNameChange}
              onKeyDown={handleFileNameSubmit}
              size="small"
            />
          </ListItem>
        )}
      </List>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={warning}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </>
  );
}

export default FileDrawer;
