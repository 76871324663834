import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import DownloadIcon from "@mui/icons-material/Download";
import { ProveButton, VerifyButton, DownloadButton } from "./Buttons";
import { StyledTextField } from "../static";

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

const OrangeIconButton = styled(IconButton)(({ theme }) => ({
  color: "#ED7014",
}));

function OutputViewer({
  privateInput,
  codeValue,
  selectedFile,
  codeOutput,
  setCodeOutput,
  isRunning,
  setIsRunning,
}) {
  const handleProve = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: codeValue,
      task: "prove",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://valida-playground-backend.onrender.com/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  const handleVerify = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: codeValue,
      task: "verify",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://valida-playground-backend.onrender.com/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  const downloadFile = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: codeValue,
      task: "verify",
      stdin: privateInput,
      language: selectedFile.slice(-1) == "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://valida-playground-backend.onrender.com/download",
      requestOptions,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "program.proof";
        document.body.appendChild(a);
        a.click();
        a.remove();
        console.log("Saved proof successfully, big bro.");
      })
      .catch((error) =>
        console.error("Error downloading the .proof file: ", error),
      );
  };

  return (
    <Stack
      sx={{ backgroundColor: "#FCFCFD", position: "relative", height: "100%" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="0 8px"
      >
        <Typography
          sx={{ color: "#000000", fontSize: 16, padding: "0 8px" }}
          variant="h6"
        >
          Output
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1} padding={1}>
          <Tooltip
            title="Generate Proof"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <ProveButton
                onClick={handleProve}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
              />
            </span>
          </Tooltip>
          <Tooltip
            title="Verify Proof"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <VerifyButton
                aria-label="verify"
                size="small"
                onClick={handleVerify}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
              />
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider />

      <Box position="relative" width="100%">
        <StyledTextField
          variant="outlined"
          placeholder="code action result will appear here"
          fullWidth
          multiline
          rows={15}
          value={codeOutput}
          InputProps={{
            readOnly: true,
            style: {
              fontSize: "14px",
              fontFamily: '"Fira Code", monospace',
            },
          }}
        />
        <Box position="fixed" bottom={16} right={16}>
          <Tooltip title="Download Proof" placement="left">
            <span>
              <DownloadButton
                onClick={downloadFile}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Stack>
  );
}

export default OutputViewer;
