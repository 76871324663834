import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import CodeMirror from "@uiw/react-codemirror";
import { indentUnit } from "@codemirror/language";
import { cpp, cppLanguage } from "@codemirror/lang-cpp";
import { rust, rustLanguage } from "@codemirror/lang-rust";
import { languages } from "@codemirror/language-data";
import { basicSetupOptions } from "../static";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MenuButton, RunButton } from "./Buttons";
import FileDrawer from "./FileDrawer";
import { codeExamples, lightTheme } from "../static";

function CodeEditor({
  privateInput,
  codeValue,
  setCodeValue,
  selectedFile,
  setSelectedFile,
  setCodeOutput,
  isRunning,
  setIsRunning,
}) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [fileContents, setFileContents] = useState(codeExamples);

  const onChange = (value) => {
    setCodeValue(value);
  };

  const handleFileClick = (fileName, fileContent = "") => {
    setFileContents((prevContents) => ({
      ...prevContents,
      [selectedFile]: codeValue,
    }));

    setSelectedFile(fileName);

    if (Object.keys(fileContents).some((name) => name === fileName)) {
      setCodeValue(fileContents[fileName] || "");
    } else {
      setCodeValue(fileContent);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const runCode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code: codeValue,
      task: "run",
      stdin: privateInput,
      language: selectedFile.slice(-1) === "c" ? "c" : "rust",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      "https://valida-playground-backend.onrender.com/process",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setCodeOutput(result.response))
      .catch((error) => console.error(error));
  };

  return (
    <Grid container sx={{ height: "calc(100vh - 64px)" }}>
      {/* Drawer grid */}
      {drawerOpen && (
        <Grid
          item
          xs={3}
          sx={{
            borderRight: "1px solid",
            borderColor: "divider",
            height: "100%",
          }}
        >
          <FileDrawer
            handleFileClick={handleFileClick}
            selectedFile={selectedFile}
            fileContents={codeValue}
            setFileContents={setFileContents}
          />
        </Grid>
      )}

      {/* Code editor grid */}
      <Grid item xs={drawerOpen ? 9 : 12} sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: "0 8px",
            backgroundColor: "#FCFCFD",
          }}
        >
          <Tooltip
            title="Toggle File Drawer"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <MenuButton onClick={toggleDrawer} />
            </span>
          </Tooltip>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#4E4E4E",
            }}
          >
            {selectedFile}
          </Typography>
          <Tooltip
            title="Run Code"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <RunButton
                onClick={runCode}
                isRunning={isRunning}
                setIsRunning={setIsRunning}
              />
            </span>
          </Tooltip>
        </Stack>
        <Box sx={{ height: "1px" }} />
        <Divider />
        <Box
          sx={{
            height: "calc(100% - 56px)",
            overflow: "hidden",
            paddingLeft: "12px",
          }}
        >
          <CodeMirror
            value={codeValue}
            placeholder={"Please enter your code here..."}
            height="100%"
            basicSetup={basicSetupOptions}
            theme={lightTheme}
            autoFocus={true}
            onChange={onChange}
            extensions={[
              selectedFile.slice(-1) === "c"
                ? cpp({ base: cppLanguage, codeLanguages: languages })
                : rust({ base: rustLanguage, codeLanguages: languages }),
              indentUnit.of("    "),
            ]}
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CodeEditor;
