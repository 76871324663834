import React from "react";
import { Box, Chip, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckIcon from "@mui/icons-material/Check";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  padding: 0,
});

export function ImportInputButton({ onClick, size = "medium" }) {
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <IconButton
        disableRipple
        color="inherit"
        aria-label="menu"
        onClick={handleButtonClick}
      >
        <UploadFileIcon fontSize={size} />
      </IconButton>
      <VisuallyHiddenInput
        ref={fileInputRef}
        type="file"
        onChange={onClick}
        multiple
      />
    </>
  );
}

export function MenuButton({ onClick, size = "medium" }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      <MenuIcon fontSize={size} />
    </IconButton>
  );
}

export function RunButton({
  onClick,
  size = "medium",
  isRunning,
  setIsRunning,
}) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="run"
      onClick={handleClick}
      disabled={isRunning}
      sx={{
        color: isRunning ? "gray" : "#F74E00",
        opacity: isRunning ? 0.5 : 1,
        pointerEvents: isRunning ? "none" : "auto",
      }}
    >
      <PlayArrowIcon fontSize={size} sx={{ color: "inherit" }} />
    </IconButton>
  );
}

export function CreateFileButton({ onClick, size = "medium" }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="create-file"
      onClick={onClick}
      sx={{ margin: 0, padding: 0 }}
    >
      <AddIcon fontSize={size} />
    </IconButton>
  );
}

export function ReportIssueButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="help"
      onClick={onClick}
    >
      <HelpOutlineIcon sx={{ height: "15px", width: "15px" }} />
    </IconButton>
  );
}

export function ImportButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="import"
      onClick={onClick}
    >
      <Box
        component="img"
        src="/upload_icon.png"
        alt="Upload icon"
        sx={{
          height: 25,
          width: 25,
          mr: 1,
        }}
      />
    </IconButton>
  );
}

export function ProveButton({ onClick, isRunning, setIsRunning }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <Button
      variant="contained"
      disabled={isRunning}
      sx={{
        color: "#FFFFFF",
        backgroundColor: "#F74E00",
        borderColor: "#F74E00",
        fontSize: 14,
        borderRadius: "5px",
        padding: "2px 8px",
        textTransform: "none",
        boxShadow: "none",
        minWidth: "auto",
        margin: 0,
        "&:hover": {
          boxShadow: "none",
          borderColor: "#F74E00",
        },
      }}
      onClick={handleClick}
      disableRipple
    >
      Prove
    </Button>
  );
}

export function VerifyButton({ onClick, isRunning, setIsRunning }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <Button
      variant="outlined"
      disabled={isRunning}
      sx={{
        color: "#F74E00",
        borderColor: "#F74E00",
        fontSize: 14,
        borderRadius: "5px",
        padding: "2px 8px",
        textTransform: "none",
        boxShadow: "none",
        minWidth: "auto",
        margin: 0,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "transparent",
          color: "#F74E00",
          borderColor: "#F74E00",
        },
      }}
      onClick={handleClick}
      disableRipple
    >
      Verify
    </Button>
  );
}

export function ShareButton({ onClick }) {
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: "#F74E00",
        color: "#FFFFFF",
        padding: "4px 8px",
        borderRadius: 1,
      }}
      onClick={onClick}
    >
      <Box
        component="img"
        src="/share_icon.png"
        alt="Share icon"
        sx={{
          height: 15,
          width: 15,
          mr: 1,
        }}
      />
      Share
    </Button>
  );
}

export function DownloadButton({ onClick, isRunning, setIsRunning }) {
  const handleClick = () => {
    setIsRunning(true);
    onClick().finally(() => setIsRunning(false));
  };

  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="download"
      onClick={handleClick}
      disabled={false}
    >
      <Box
        component="img"
        src="/download_icon.png"
        alt="Download icon"
        sx={{
          height: 30,
          width: 30,
          filter: isRunning ? "grayscale(1)" : null,
        }}
      />
    </IconButton>
  );
}
