import * as React from "react";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Spacer } from "@mui/system";
import { ReportIssueButton, ShareButton } from "./Buttons";
import LanguageSwitcher from "./LanguageSwitcher";
import MachineInfo from "./MachineInfo";
import { Octokit, App } from "octokit";

const octokit = new Octokit({
  auth: process.env.REACT_APP_GITHUB_TOKEN,
});

function Header({ codeValue, privateInput, filename }) {
  const handleShare = async () => {
    console.log("hello");
    try {
      const response = await octokit.request("POST /gists", {
        public: false,
        description: "",
        files: {
          [filename]: {
            content: codeValue,
          },
        },
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      console.log("Gist created successfully:", response.data);

      const gistUrl = response.data.html_url;
      window.open(gistUrl, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.error("Error creating gist:", error);
    }
  };

  const handleReportIssue = () => {
    window.open(
      "https://github.com/lita-xyz/llvm-valida-releases/issues",
      "_blank",
    );
  };

  return (
    <header className="app-header">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        {/* LHS of Header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            src="/lita_logo.png"
            alt="Logo"
            sx={{ height: 35, width: 35, padding: "0 4px" }}
          />
          <Chip
            label="PLAYGROUND"
            sx={{
              fontSize: "10px",
              height: "20px",
              minWidth: "auto",
              border: "1px solid #D1D1D1",
            }}
          />
          <Tooltip
            title="Report Issue"
            placement="right"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <ReportIssueButton onClick={handleReportIssue} />
            </span>
          </Tooltip>
        </Stack>

        {/* RHS of Header */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <MachineInfo />
          <LanguageSwitcher filename={filename} />
          <ShareButton onClick={handleShare} />
        </Stack>
      </Stack>
    </header>
  );
}

export default Header;
