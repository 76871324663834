import React from "react";
import InputEditor from "./InputEditor";
import OutputViewer from "./OutputViewer";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";

function RightContainer({
  privateInput,
  setPrivateInput,
  codeValue,
  selectedFile,
  codeOutput,
  setCodeOutput,
  isRunning,
  setIsRunning,
}) {
  return (
    <Stack>
      <InputEditor
        privateInput={privateInput}
        setPrivateInput={setPrivateInput}
      />
      <Divider />
      <OutputViewer
        privateInput={privateInput}
        codeValue={codeValue}
        selectedFile={selectedFile}
        codeOutput={codeOutput}
        setCodeOutput={setCodeOutput}
        isRunning={isRunning}
        setIsRunning={setIsRunning}
      />
    </Stack>
  );
}

export default RightContainer;
