import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const basicSetupOptions = {
  history: true,
  drawSelection: true,
  foldGutter: true,
  allowMultipleSelections: true,
  bracketMatching: true,
  crosshairCursor: true,
  autocompletion: true,
};

export const lightTheme = createTheme({
  theme: "light",
  settings: {
    background: "#ffffff",
    backgroundImage: "",
    foreground: "#8D8D8D",
    caret: "#AEAFAD",
    selectionMatch: "#D6D6D6",
    gutterBackground: "#FFFFFF",
    gutterForeground: "#4D4D4C",
    gutterBorder: "#ffffff",
    gutterActiveForeground: "",
  },
  styles: [
    { tag: t.comment, color: "#77bb41" },
    { tag: t.typeName, color: "#194a7b" },
    { tag: t.keyword, color: "#4F69FB" },
    { tag: t.definition(t.variableName), color: "#FB844D" },
    { tag: t.number, color: "#36D299" },
    { tag: t.meta, color: "#194a7b" },
  ],
});

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#FFFFFF",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    color: "#000",
  },
  "&::placeholder": {
    color: "#D1D1D1",
    opacity: 1,
  },
});

export const codeExamples = {
  "read-write.rs": `/*
 * Copyright © [2024] Lita Inc. All Rights Reserved.
 *
 * This software and associated documentation files (the “Software”) are owned by
 * Lita Inc. and are protected by copyright law and international treaties.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy 
 * of this software and associated documentation files (the “Software”), to use 
 * the Software for personal, non-commercial purposes only, subject to the 
 * following conditions:
 *
 * 1. The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 * 2. The Software may not be used for commercial purposes without the express 
 * written permission of Lita Inc.
 *
 * For inquiries regarding commercial use, please contact us at: 
 * ops@lita.foundation
 *
 * THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE 
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
 * SOFTWARE.
*/

#![no_std]
#![feature(start)]

use core::panic::PanicInfo;

#[panic_handler]
fn panic(_info: &PanicInfo) -> ! {
    loop {}
}

#[start]
fn main(_argc: isize, _argv: *const *const u8) -> isize {

    extern { fn read_stdin() -> u32;}
    extern { fn write_stdout(n: u32);}

    unsafe {
        let n = read_stdin();
        write_stdout(n);
    }
    return 0;
}`,
  "reverse.c": `/*
 * Copyright © [2024] Lita Inc. All Rights Reserved.
 *
 * This software and associated documentation files (the “Software”) are owned by 
 * Lita Inc. and are protected by copyright law and international treaties.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy 
 * of this software and associated documentation files (the “Software”), to use 
 * the Software for personal, non-commercial purposes only, subject to the 
 * following conditions:
 *
 * 1. The above copyright notice and this permission notice shall be included in 
 * all copies or substantial portions of the Software.
 * 2. The Software may not be used for commercial purposes without the express 
 * written permission of Lita Inc.
 *
 * For inquiries regarding commercial use, please contact us at: 
 * ops@lita.foundation
 *
 * THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE 
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER 
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, 
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE 
 * SOFTWARE.
*/

const unsigned EOF = 0xFFFFFFFF;
#define BUF_LEN 2048

int main() {
    unsigned buf[BUF_LEN];
    unsigned len = 0;
    while (len < BUF_LEN) {
        unsigned c = __builtin_delendum_read_advice();
        if (c == EOF) {
            break;
        } else {
            buf[len] = c;
            len++;
        }
    }
    for (unsigned i = 0; i < len / 2; i++) {
        unsigned j = len - 1 - i;
        unsigned tmp = buf[i];
        buf[i] = buf[j];
        buf[j] = tmp;
    }
    for (unsigned i = 0; i < len; i++) {
        __builtin_delendum_write(buf[i]);
    }
}`,
  "checksum.c": `/*
 * Copyright © [2024] Lita Inc. All Rights Reserved.
 *
 * This software and associated documentation files (the “Software”) are owned by 
 * Lita Inc. and are protected by copyright law and international treaties.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy 
 * of this software and associated documentation files (the “Software”), to use 
 * the Software for personal, non-commercial purposes only, subject to the 
 * following conditions:
 *
 * 1. The above copyright notice and this permission notice shall be included in 
 * all copies or substantial portions of the Software.
 * 2. The Software may not be used for commercial purposes without the express 
 * written permission of Lita Inc.
 *
 * For inquiries regarding commercial use, please contact us at: 
 * ops@lita.foundation
 *
 * THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE 
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER 
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, 
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE 
 * SOFTWARE.
*/

const unsigned EOF = 0xFFFFFFFF;

int main() {
    unsigned sum = 0;
    while (1) {
        unsigned c = __builtin_delendum_read_advice();
        if (c == EOF) {
            break;
        } else {
          sum += c;
        }
    }
    __builtin_delendum_write(sum);
}
`,
};
