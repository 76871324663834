import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function MachineInfo() {
  return (
    <Paper
      elevation={0}
      spacing={0}
      sx={{
        borderRadius: "24px",
        backgroundColor: "#FFFFFF",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#F74E00",
      }}
    >
      <Stack
        direction="row"
        spacing={1.25}
        justifyContent="space-between"
        alignItems="center"
        padding="4px 10px"
      >
        <Typography sx={{ fontSize: 14, color: "#9E9E9E" }}>Machine</Typography>
        <Typography sx={{ fontSize: 14, color: "#F74E00" }}>
          512MB RAM
        </Typography>
      </Stack>
    </Paper>
  );
}

export default MachineInfo;
