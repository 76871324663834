import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Divider } from "@mui/material";
import { basicSetupOptions, lightTheme, StyledTextField } from "../static";
import { ImportInputButton } from "./Buttons";

function InputEditor({ privateInput, setPrivateInput }) {
  const [open, setOpen] = React.useState(false);

  const openSnackbar = () => {
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const onPrivateInputChange = React.useCallback((event) => {
    setPrivateInput(event.target.value);
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPrivateInput(e.target.result);
      };
      reader.onerror = () => {
        console.error("File reading has failed.");
      };
      reader.readAsText(file);
    } else {
      openSnackbar();
    }
  };

  return (
    <>
      <Stack sx={{ backgroundColor: "#FCFCFD" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="0 8px"
        >
          <Typography
            sx={{ color: "#000000", fontSize: 16, padding: "0 8px" }}
            variant="h6"
          >
            Inputs
          </Typography>
          <Tooltip title="Import Input from File" placement="left">
            <span>
              <ImportInputButton onClick={handleFileUpload} />
            </span>
          </Tooltip>
        </Stack>
        <Box sx={{ height: "1px" }} /> {/* Spacer, prevents off by 1 */}
        <Divider />
        <StyledTextField
          variant="outlined"
          placeholder="type here to add inputs"
          fullWidth
          multiline
          rows={12}
          value={privateInput}
          onChange={onPrivateInputChange}
        />
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        message="File Type Error: Please upload a .txt file"
      />
    </>
  );
}

export default InputEditor;
